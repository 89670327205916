var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
		's-header-mobile__nav__list-wrapper',
		("s-header-mobile__nav__list-wrapper--level-" + _vm.thisLevel)
	],attrs:{"data-active":String(_vm.isActive),"data-has-active-child":_vm.hasActiveChild,"id":("s-header-mobile__nav__" + _vm.id)}},[(_vm.thisLevel === 1)?_c('nav',{attrs:{"role":"navigation","aria-label":_vm.ariaLabel}},[_c('ul',{staticClass:"s-header-mobile__nav__list"},_vm._l((_vm.menuItems),function(menuItem,index){return _c('MenuItem',{key:("menuitem-" + (menuItem.id)),attrs:{"item":menuItem,"level":_vm.thisLevel,"menuType":_vm.menuType,"positionInList":_vm.getPositionInList(_vm.menuItems.length, index),"disableFocusTrap":_vm.disableFocusTrapHandler(_vm.menuItems.length, index)}})}),1)]):_c('ul',{staticClass:"s-header-mobile__nav__list"},[(_vm.topItem)?_c('li',{class:[
				's-header-mobile__nav__list-item',
				's-header-mobile__nav__list-item--top-item'
			]},[_c('a',{ref:("top-item-link-" + _vm.id),class:[
					's-header-mobile__nav__link',
					's-header-mobile__nav__link--top-item'
				],attrs:{"href":_vm.topItem.uri,"data-index-of-list":"first"}},[_vm._v(" "+_vm._s(_vm.topItem.name)+" ")])]):_vm._e(),_vm._l((_vm.menuItems),function(menuItem,index){return _c('MenuItem',{key:("menuitem-" + (menuItem.id)),attrs:{"item":menuItem,"level":_vm.thisLevel,"positionInList":_vm.getPositionInList(_vm.menuItems.length + 1, index + 1),"disableFocusTrap":_vm.disableFocusTrapHandler(_vm.menuItems.length, index)}})})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }