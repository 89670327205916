import axios from 'axios'
import { ISuggestion } from '../store/state'

const siteVisionHeader = {
	'X-Requested-With': 'XMLHttpRequest',
	Accept: 'application/json',
	'Content-type': 'text/json'
}

interface ISearchResponse {
	data: ISuggestion[]
}

export const search = (
	query: string,
	searchUrl: string
): Promise<ISearchResponse> =>
	axios.get(`${searchUrl}`, {
		headers: { ...siteVisionHeader },
		params: {
			query
		}
	})
