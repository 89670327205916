





















import Vue from 'vue'
import { ILabels } from '../store/state'
import { EventBus } from '../utils/event-bus'

interface MTMethods {
	toggleMenu(): void
	escapeKeyHandler(): void
	mightTabOutOfApp(): void
}
interface MTComputed {
	labels(): ILabels
}
interface MTProps {
	expanded: boolean
	ariaControls: string
}

export default Vue.extend<{}, MTMethods, MTComputed, MTProps>({
	name: 'MenuToggle',
	methods: {
		toggleMenu() {
			/**
			 * Telling parent component to toggle menu.
			 */
			this.$emit('toggleMenuEvent', 'toggleMenu')
		},
		escapeKeyHandler() {
			EventBus.$emit('esc-key-handler')
		},
		mightTabOutOfApp() {
			EventBus.$emit('might-tab-out-of-app')
		}
	},
	computed: {
		labels() {
			return this.$store.state.labels
		}
	},
	props: {
		expanded: Boolean,
		ariaControls: String
	}
})
