
































































import Vue from 'vue'
import MenuItem from './MenuItem.vue'
import { IMenuItem } from '../store/state'

interface MLData {
	isExpanded: boolean
}
interface MLProps {
	parentLevel: Number
	menuType?: string
	menuItems: IMenuItem[]
	topItem?: IMenuItem
	id?: string
	ariaLabel?: string
	isSubMenu: boolean
	disableLastItemTrap: boolean
}

interface MLMethods {
	findNestedMenuItem(
		haystack: IMenuItem[],
		needle: string | null
	): IMenuItem | null
	getPositionInList(listLength: number, index: number): string | null
	disableFocusTrapHandler(listLength: number, index: number): void
}

interface MLComputed {
	thisLevel: Number
	hasActiveChild: boolean
	isActive: boolean
	activeSubmenu: string
}

const component: Function = Vue.extend<MLData, MLMethods, MLComputed, MLProps>({
	name: 'MenuList',
	data() {
		return {
			isExpanded: false
		}
	},
	watch: {
		isActive: function(active) {
			if (active) {
				const firstTab = this.$refs[`top-item-link-${this.id}`] as HTMLElement
				setTimeout(() => firstTab && firstTab.focus(), 200)
			}
		}
	},
	methods: {
		findNestedMenuItem(haystack, needle) {
			/**
			 * Fetches menu item wherever it's located in the tree.
			 */
			if (!needle) return null
			for (let item of haystack) {
				if (item.id === needle) return item
				else if (item && item.children) {
					const childrenResult = this.findNestedMenuItem(item.children, needle)
					if (childrenResult) return childrenResult
				}
			}
			return null
		},
		getPositionInList(listLength, index) {
			/**
			 * Gets information about the placement of an item
			 * in a list. Eg. first, second, penultimate, last)
			 */
			const isFirstChild: boolean = index === 0
			const isSecondChild: boolean = index === 1
			const isPenultimateChild: boolean = index === listLength - 2
			const isLastChild: boolean = index === listLength - 1

			const output: string[] = []

			if (isFirstChild) output.push('first')
			if (isSecondChild) output.push('second')
			if (isPenultimateChild) output.push('penultimate')
			if (isLastChild) output.push('last')
			return (output.length > 0 && output.join(' ')) || null
		},
		disableFocusTrapHandler(listLength, index) {
			/**
			 * Disables the focus behavior for the lists last item.
			 * Useful if two MenuLists are placed after one another, eg. when
			 * the main menu is directly followed by the secondary menu.
			 * Putting this on the main menu, will cause a tab from the last item to
			 * naturally move forward to the secondary menu.
			 */
			return this.disableLastItemTrap && listLength === index + 1
		}
	},
	computed: {
		thisLevel() {
			return Number(this.parentLevel) + 1
		},
		hasActiveChild() {
			const activeSubmenu: string = this.$store.getters.activeSubmenu!

			//  If is level 1
			if (activeSubmenu === this.id) return false

			// If is > level 1
			const me: IMenuItem | null = this.findNestedMenuItem(
				[
					...this.$store.getters.mainMenuItems,
					...this.$store.getters.secondaryMenuItems
				],
				this.id || null
			)
			if (activeSubmenu && me && me.children) {
				const match: IMenuItem | null = this.findNestedMenuItem(
					me.children,
					activeSubmenu
				)
				return match !== null
			}
			return false
		},
		isActive() {
			const activeSubmenu: string = this.$store.getters.activeSubmenu!
			const isParentLevelAndNoSub: boolean =
				this.parentLevel === 0 && !activeSubmenu
			const isActiveSub: boolean =
				activeSubmenu !== null && this.id === activeSubmenu

			return isParentLevelAndNoSub || isActiveSub
		},
		activeSubmenu() {
			return this.$store.getters.activeSubmenu
		}
	},
	props: {
		parentLevel: Number,
		menuType: String,
		menuItems: Array,
		topItem: Object,
		id: String,
		ariaLabel: String,
		isSubMenu: Boolean,
		disableLastItemTrap: Boolean
	},
	components: {
		MenuItem
	}
})
export default component
