import { MutationTree } from 'vuex'
import { IState, ISuggestion, IActiveSuggestion } from './state'
import { ActionPayload } from './actions'

type MutationHandler<PayloadType> = (
	state: IState,
	payload: ActionPayload<PayloadType>
) => any

const setState: MutationHandler<IState> = (state, { payload }) => {
	Object.assign(state, payload)
}

const setActiveSubmenu: MutationHandler<string | undefined> = (state, { payload }) => {
	state.activeSubmenu = payload
} 

const setSuggestions: MutationHandler<ISuggestion[]> = (state, { payload }) =>
	(state.suggestions = payload || undefined)

const setSearchQuery: MutationHandler<string | undefined> = (
	state,
	{ payload }
) => (state.searchQuery = payload || undefined)

const setLastTyped: MutationHandler<string | undefined> = (
	state,
	{ payload }
) => (state.lastTyped = payload || undefined)

const setShowSuggestions: MutationHandler<boolean> = (state, { payload }) =>
	(state.showSuggestions = payload)

const setActiveSuggestion: MutationHandler<IActiveSuggestion> = (
	state,
	{ payload }
) => (state.activeSuggestion = payload)

const mutations: MutationTree<IState> = {
	setState,
	setActiveSubmenu,
	setSuggestions,
	setSearchQuery,
	setLastTyped,
	setShowSuggestions,
	setActiveSuggestion
}

export default mutations
