























































import Vue from 'vue'
import { IMenuItem } from '../store/state'
import { setActiveSubmenu } from '../store/actions'
import { EventBus } from '../utils/event-bus'

interface MIMethods {
	setSubmenu(id: string): void
	addFocusOutListener(): void
	setFocusOnElement(): void
}
interface MIComputed {
	hasChildren: boolean
	children: IMenuItem[]
	subMenuId: string
	activeSubMenu: string
	subMenuActive: boolean
	isCurrentPage: boolean
}
interface MIProps {
	item: IMenuItem
	level: number
	menuType?: string
	positionInList: string | null
	disableFocusTrap: boolean
}

export default Vue.extend<{}, MIMethods, MIComputed, MIProps>({
	name: 'MenuItem',
	props: {
		item: Object,
		level: Number,
		menuType: String,
		positionInList: String,
		disableFocusTrap: Boolean
	},
	mounted() {
		this.addFocusOutListener()
		EventBus.$on('set-focus-to-item', (id: string | undefined) => {
			if (id && id === this.item.id) this.setFocusOnElement()
		})
	},
	methods: {
		setSubmenu(id) {
			/**
			 * Sets active submenu state
			 */
			this.$store.dispatch(setActiveSubmenu({ id }))
		},
		addFocusOutListener() {
			/**
			 * Sets focus on webapps first tabbable element if
			 * focusout while the item is last in its closest <ul>.
			 * Since there will always be only one <ul> at the time in view
			 * this means that the last item of a ul also is the last tabbable
			 * element of the entire menu.
			 */

			/**
			 * Abort if one of:
			 * - Is not last item in its list.
			 * - Focus trap behaviour is unwanted through props.
			 */
			if (
				!this.positionInList?.split(' ').includes('last') ||
				this.disableFocusTrap
			)
				return

			/**
			 * This button or link. Picks the one that is
			 * selected through v-if in the template
			 */
			const thisElement = this.$refs[
				`submenu-trigger-${this.item.id}`
			] as HTMLElement

			const focusOutAction = () => {
				/**
				 * Set focus on apps first tabbable element if the element that was tabbed to is not the
				 * penultimate child of the active list, ergo if the tab was a shift tab
				 */
				setTimeout(() => {
					const activeElement = document.activeElement as HTMLElement
					const activeElementDataIndex: string =
						activeElement?.dataset?.indexOfList || ''

					if (!/penultimate/.test(activeElementDataIndex))
						EventBus.$emit('focus-on-first-tabbable')
				}, 20)
			}
			thisElement.addEventListener('focusout', focusOutAction)
		},
		setFocusOnElement() {
			const element = this.$refs[
				`submenu-trigger-${this.item.id}`
			] as HTMLElement
			setTimeout(() => element.focus(), 50)
		}
	},
	computed: {
		hasChildren() {
			return this.item.children ? this.item.children.length > 0 : false
		},
		children() {
			return this.item.children ? this.item.children : []
		},
		subMenuId() {
			return this.item.id
		},
		activeSubMenu() {
			return this.$store.getters.activeSubmenu
		},
		subMenuActive() {
			return this.activeSubMenu === this.subMenuId
		},
		isCurrentPage() {
			return this.$store.state.currentPage === this.item.id
		}
	},
	components: {
		MenuList: () => import('./MenuList.vue')
	}
})
