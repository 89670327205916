








import Vue from 'vue'
import { IMenuItem } from '../store/state'

interface SMHComputed {
	activeSubmenuName?: string
}
interface SMHMethods {
	findNestedMenuItem(haystack: IMenuItem[], needle: string): IMenuItem | null
}

export default Vue.extend<{}, SMHMethods, SMHComputed, {}>({
	name: 'SubmenuHeading',
	methods: {
		findNestedMenuItem(haystack, needle) {
			/**
			 * Fetch menu item wherever it's located in the tree.
			 */
			for (let item of haystack) {
				if (item.id === needle) return item
				else if (item.children) {
					const childrenResult = this.findNestedMenuItem(item.children, needle)
					if (childrenResult) return childrenResult
				}
			}
			return null
		}
	},
	computed: {
		activeSubmenuName() {
			const activeSubmenuId = this.$store.getters.activeSubmenu

			const output = activeSubmenuId
				? this.findNestedMenuItem(
						[
							...this.$store.getters.mainMenuItems,
							...this.$store.getters.secondaryMenuItems
						],
						activeSubmenuId
				  )
				: null
			return output ? output.name : ''
		}
	}
})
