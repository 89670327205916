import { Getter } from 'vuex'
import { IState } from './state'

const mainMenuItems: Getter<IState, IState> = state => state.menuItems
const secondaryMenuItems: Getter<IState, IState> = state =>
	state.secondaryMenuItems
const activeSubmenu: Getter<IState, IState> = state => state.activeSubmenu
const suggestions: Getter<IState, IState> = state => state.suggestions
const searchQuery: Getter<IState, IState> = state => state.searchQuery
const lastTyped: Getter<IState, IState> = state => state.lastTyped
const showSuggestions: Getter<IState, IState> = state => state.showSuggestions
const activeSuggestion: Getter<IState, IState> = state => state.activeSuggestion

export default {
	mainMenuItems,
	secondaryMenuItems,
	activeSubmenu,
	suggestions,
	searchQuery,
	lastTyped,
	showSuggestions,
	activeSuggestion
}
