import { IActiveSuggestion, IState } from './state'
import * as sitevisionAPI from '../utils/sitevision-api'
import { ActionTree, Store, ActionContext } from 'vuex'
import { EventBus } from '../utils/event-bus'

type PayloadType =
	| 'initApp'
	| 'setState'
	| 'setSuggestions'
	| 'setSearchQuery'
	| 'setShowSuggestions'
	| 'setActiveSubmenu'
	| 'setActiveSuggestion'
export type ActionPayload<ActionData> = {
	type: PayloadType
	payload: ActionData
}

type ActionHandler<ActionData> = (
	this: Store<IState>,
	injectee: ActionContext<IState, IState>,
	payload: ActionPayload<ActionData>
) => any
type ActionCreator<ActionData> = (data: ActionData) => ActionPayload<ActionData>

interface ISetActiveSubmenu {
	id: string | undefined
	origin?: string
}

// Action creators
export const initApp: ActionCreator<IState> = state => ({
	type: 'initApp',
	payload: state
})
export const setSuggestions: ActionCreator<string | undefined> = payload => ({
	type: 'setSuggestions',
	payload
})
export const setSearchQuery: ActionCreator<string | undefined> = payload => ({
	type: 'setSearchQuery',
	payload
})
export const setShowSuggestions: ActionCreator<boolean> = payload => ({
	type: 'setShowSuggestions',
	payload
})
export const setActiveSubmenu: ActionCreator<ISetActiveSubmenu> = payload => ({
	type: 'setActiveSubmenu',
	payload
})
export const setActiveSuggestion: ActionCreator<IActiveSuggestion> = payload => ({
	type: 'setActiveSuggestion',
	payload
})

// Action handlers
const initAppAction: ActionHandler<IState> = ({ state, commit }, { payload }) => {
	commit({
		type: 'setState',
		payload
	})
}
const setSuggestionsAction: ActionHandler<string> = (
	{ state, commit },
	{ payload }
) => {
	return sitevisionAPI.search(payload, state.apiUrls.search).then(response => {
		commit({
			type: 'setSuggestions',
			payload: response.data
		})

		if( response.data.length === 0 ){
			commit({
				type: 'setActiveSuggestion',
				payload: undefined
			})
		}
	})
}
const setSearchQueryAction: ActionHandler<string> = (
	{ commit },
	{ payload }
) => {
	commit({
		type: 'setSearchQuery',
		payload: payload
	})
}
const setShowSuggestionsAction: ActionHandler<boolean> = (
	{ commit },
	{ payload }
) => {
	commit({
		type: 'setShowSuggestions',
		payload: payload
	})

	if (false === payload) {
		commit({
			type: 'setSuggestions',
			payload: undefined
		})
		commit({
			type: 'setActiveSuggestion',
			payload: undefined
		})
	}
}

const setActiveSubmenuAction: ActionHandler<ISetActiveSubmenu> = (
	{ commit },
	{ payload }
) => {
	commit({
		type: 'setActiveSubmenu',
		payload: payload.id
	})
	if (payload.origin) EventBus.$emit('set-focus-to-item', payload.origin)
}
const setActiveSuggestionAction: ActionHandler<IActiveSuggestion> = (
	{ commit },
	{ payload }
) =>
	commit({
		type: 'setActiveSuggestion',
		payload
	})

const actions: ActionTree<IState, IState> = {
	initApp: initAppAction,
	setSuggestions: setSuggestionsAction,
	setSearchQuery: setSearchQueryAction,
	setShowSuggestions: setShowSuggestionsAction,
	setActiveSubmenu: setActiveSubmenuAction,
	setActiveSuggestion: setActiveSuggestionAction
}

export default actions
