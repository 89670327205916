



































import Vue from 'vue'
import { setSearchQuery, setActiveSuggestion } from '../store/actions'
import { ISuggestion, ILabels } from '../store/state'
import { EventBus } from '../utils/event-bus'

interface SWMethods {
	getResultsName(input: string): string
	navigateSuggestions(direction: string): void
	focusOnSearchButton(): void
	setSearchInputValue(input: string): void
	setSearchQuery(value?: string): void
	clickOutside(element: HTMLElement): void
	setAsInputDescendant(index?: number): void
	suggestionIsActive(): boolean
	clickSuggestion(value: string, index: number): void
}

interface SWComputed {
	suggestions: ISuggestion[]
	searchQuery: string
	lastTyped: string
	showSuggestions: boolean
	labels: ILabels
	hitPresenter: string
}

export default Vue.extend<{}, SWMethods, SWComputed, {}>({
	name: 'SearchSuggestions',
	mounted() {
		EventBus.$on('navigate-suggestion', (direction: string) =>
			this.navigateSuggestions(direction)
		)
		EventBus.$on('pass-app-wrapper', (element: HTMLElement) =>
			this.clickOutside(element)
		)
		EventBus.$on('suggestion-is-active', () => this.suggestionIsActive())
	},
	watch: {
		showSuggestions: function(value) {
			if (!value || value.length === 0) this.setAsInputDescendant(undefined)
		},
		suggestions: function(value) {
			if (!value) this.setAsInputDescendant(undefined)
		}
	},
	methods: {
		getResultsName(input: string) {
			/**
			 * Returns value of search suggestion. Sets search term in bold.
			 */
			const output = input.replace(
				eval(`/${this.lastTyped}/gi`),
				`<b>${this.lastTyped}</b>`
			)
			return output
		},
		setSearchInputValue(input: string) {
			/**
			 * Sets search input value. Hides suggestions.
			 */
			this.setSearchQuery(input)
			this.focusOnSearchButton()
		},
		navigateSuggestions(direction) {
			/**
			 * Handles focus navigation
			 */
			const suggestionsList =
				this.$refs['suggestions-elements'] &&
				(this.$refs['suggestions-elements'] as HTMLElement[])
			if (suggestionsList && suggestionsList.length > 0) {
				const currentlyActive = suggestionsList.find(
					(x: HTMLElement) => x.dataset.active === 'true'
				)
				const increaseAmount = (direction === 'down' && 1) || -1

				if (currentlyActive) {
					const targetIndex =
						suggestionsList.indexOf(currentlyActive) + increaseAmount
					currentlyActive.dataset.active = 'false'
					if (suggestionsList[targetIndex]) {
						suggestionsList[targetIndex].dataset.active = 'true'
						const text = suggestionsList[targetIndex].textContent
						if (text) {
							this.setSearchQuery(text)

							this.$store.dispatch(
								setActiveSuggestion({
									index: targetIndex,
									text
								})
							)
						}
					} else {
						this.$store.commit({
							type: 'setActiveSuggestion',
							payload: undefined
						})
						this.setSearchQuery(this.$store.getters.lastTyped)
					}
				} else {
					const targetIndex =
						direction === 'down' ? 0 : suggestionsList.length - 1
					suggestionsList[targetIndex].dataset.active = 'true'
					const text = suggestionsList[targetIndex].textContent
					if (text) {
						this.setSearchQuery(text)
						this.$store.dispatch(
							setActiveSuggestion({
								index: targetIndex,
								text
							})
						)
					}
				}
			}

			// if (!suggestionsList) return

			// for (const [index, button] of suggestionsList.entries()) {
			// 	if (button !== activeElement) continue

			// 	let siblingElement = null

			// 	siblingElement =
			// 		direction === 'down'
			// 			? suggestionsList.length > index + 1 && suggestionsList[index + 1]
			// 			: index > 0 && suggestionsList[index - 1]

			// 	if (siblingElement) {
			// 		;(siblingElement as HTMLElement).dataset.active = 'true'
			// 	} else {
			// 		EventBus.$emit('focus-on-search-input')
			// 	}
			// }
		},
		focusOnSearchButton() {
			/**
			 * Ordering a method to be called in SearchWrapper.vue
			 */
			EventBus.$emit('focus-on-search-button')
		},
		setSearchQuery(value) {
			/**
			 * Sets value of search input field in state
			 */
			this.$store.dispatch(setSearchQuery(value))
		},

		clickOutside(element) {
			const eventFunction = (event: any) => {
				const suggElement = this.$refs[
					'suggestions-wrapper'
				] as HTMLButtonElement
				const paths = event.path
				if (!paths.includes(suggElement)) EventBus.$emit('hide-suggestions')
			}
			element.addEventListener('click', eventFunction)
		},
		setAsInputDescendant(index) {
			EventBus.$emit(
				'set-active-descendant',
				index ? `s-header-mobile__search__suggestion-${index}` : undefined
			)
		},
		suggestionIsActive() {
			const suggestionsList =
				this.$refs['suggestions-elements'] &&
				(this.$refs['suggestions-elements'] as HTMLElement[])

			if (suggestionsList && suggestionsList.length > 0) {
				const currentlyActive = suggestionsList.find(
					(x: HTMLElement) => x.dataset.active === 'true'
				)

				if (currentlyActive) return true
			}

			return false
		},
		clickSuggestion(value, index) {
			this.setAsInputDescendant(index)
			this.setSearchInputValue(value)
			EventBus.$emit('submit-form')
		}
	},
	computed: {
		suggestions() {
			return this.$store.getters.suggestions || []
		},
		searchQuery() {
			return this.$store.getters.searchQuery
		},
		lastTyped() {
			return this.$store.getters.lastTyped
		},
		showSuggestions() {
			return this.$store.getters.showSuggestions
		},
		labels() {
			return this.$store.state.labels
		},
		hitPresenter() {
			return (
				(this.suggestions.length > 0 &&
					`${this.suggestions.length} ${this.labels.suggestions}`) ||
				''
			)
		}
	}
})
