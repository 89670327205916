var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{class:['s-header-mobile__nav__list-item']},[(_vm.hasChildren)?_c('button',{ref:("submenu-trigger-" + (_vm.item.id)),class:[
			's-header-mobile__nav__button-submenu',
			_vm.menuType === 'secondary' &&
				's-header-mobile__nav__button-submenu--secondary-menu',
			_vm.isCurrentPage && 's-header-mobile__nav__button-submenu--active'
		],attrs:{"aria-expanded":String(_vm.subMenuActive),"aria-controls":("s-main-menu-mobile-" + (_vm.item.id)),"data-index-of-list":_vm.positionInList},on:{"click":function($event){return _vm.setSubmenu(_vm.item.id)}}},[_c('span',[_vm._v(" "+_vm._s(_vm.item.name)+" ")]),_c('span',{staticClass:"s-header-mobile__nav__button-submenu__icon"})]):_c('a',{ref:("submenu-trigger-" + (_vm.item.id)),class:[
			's-header-mobile__nav__link',
			_vm.menuType === 'secondary' &&
				's-header-mobile__nav__link--secondary-menu',
			_vm.isCurrentPage && 's-header-mobile__nav__link--active'
		],attrs:{"href":_vm.item.uri,"lang":_vm.item.lang && _vm.item.lang,"data-index-of-list":_vm.positionInList}},[_vm._v(" "+_vm._s(_vm.item.name)+" ")]),(_vm.hasChildren)?_c('MenuList',{attrs:{"topItem":_vm.item,"menuItems":_vm.children,"parentLevel":_vm.level,"id":_vm.subMenuId}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }