











import Vue from 'vue'
import { IMenuItem, ILabels } from '../store/state'
import { setActiveSubmenu } from '../store/actions'
import { EventBus } from '../utils/event-bus'

interface SBBMethods {
	goBack(): void
	findParent(haystack: IMenuItem[], needle: string): string | null
	mightTabOutOfApp(): void
}
interface SBBComputed {
	labels: ILabels
}

export default Vue.extend<{}, SBBMethods, SBBComputed, {}>({
	name: 'SubmenuBackButton',
	methods: {
		findParent(haystack, needle) {
			/**
			 * Finds parent item in menu
			 * Returns null if parent is at level 1.
			 */
			if (haystack.find((x: IMenuItem) => x.id === needle)) return null

			for (let item of haystack) {
				if (
					item.children &&
					item.children.some(
						(child: IMenuItem) => child.id && child.id === needle
					)
				)
					return item.id
				else if (item && item.children) {
					const childrenResult = this.findParent(item.children, needle)
					if (childrenResult) return childrenResult
				}
			}

			return null
		},
		goBack() {
			/**
			 * Navigates backwards in menu
			 */

			const activeSubmenu: string | undefined = this.$store.getters
				.activeSubmenu

			const parent: string | undefined =
				(activeSubmenu &&
					this.findParent(
						[
							...this.$store.getters.mainMenuItems,
							...this.$store.getters.secondaryMenuItems
						],
						activeSubmenu
					)) ||
				undefined

			const payload = { id: parent || undefined, origin: activeSubmenu }

			setTimeout(() => this.$store.dispatch(setActiveSubmenu(payload)), 5)
		},
		mightTabOutOfApp() {
			EventBus.$emit('might-tab-out-of-app')
		}
	},
	computed: {
		labels() {
			return this.$store.state.labels
		}
	}
})
