

















































import Vue from 'vue'
import MenuList from './components/MenuList.vue'
import Logotype from './components/Logotype.vue'
import SearchWrapper from './components/SearchWrapper.vue'
import TipButton from './components/TipButton.vue'
import SubmenuBackButton from './components/SubmenuBackButton.vue'
import SubmenuHeading from './components/SubmenuHeading.vue'
import MenuToggle from './components/MenuToggle.vue'
import { IMenuItem, ILabels } from './store/state'
import { EventBus } from './utils/event-bus'

interface AppData {
	menuExpanded: boolean
	menuId: string
	desktopThreshold: number
	desktop: boolean
}

interface AppMethods {
	closeMenu(): void
	toggleMenu(): void
	escapeKeyHandler(): void
	tabindexHandler(): void
	initialTabHandler(): void
	bodyFreezeHandler(isActive: boolean): void
	desktopModeHandler(): void
	focusOnFirstTabbable(): void
	focusOnLastTabbable(): void
	mightTabOutOfApp(): void
	clickOutsideSuggestions(event: any): void
}
interface AppComputed {
	isLangSwedish: boolean
	mainMenuItems: IMenuItem[]
	secondaryMenuItems: IMenuItem[]
	activeSubmenuId?: string
	noActiveSubmenu: boolean
	labels: ILabels
}

export default Vue.extend<AppData, AppMethods, AppComputed, {}>({
	name: 'App',
	data() {
		return {
			menuId: 's-header-mobile-menu',
			menuExpanded: false,
			desktopThreshold: 1025,
			desktop: true
		}
	},
	mounted() {
		this.desktopModeHandler()
		this.initialTabHandler()
		EventBus.$on('focus-on-first-tabbable', () => this.focusOnFirstTabbable())
		EventBus.$on('esc-key-handler', () => this.escapeKeyHandler())
		EventBus.$on('might-tab-out-of-app', () => this.mightTabOutOfApp())
		EventBus.$emit('pass-app-wrapper', this.$refs['app-wrapper'] as HTMLElement)
	},
	methods: {
		closeMenu() {
			/**
			 * Hides mobile menu and resets active sub menu if set.
			 */
			this.menuExpanded = false
			this.$store.commit({
				type: 'setActiveSubmenu',
				payload: undefined
			})
		},
		toggleMenu() {
			/**
			 * Shows/hides mobile menu.
			 */
			this.menuExpanded = !this.menuExpanded
			if (!this.menuExpanded) this.closeMenu()
		},
		escapeKeyHandler() {
			/**
			 * Closes menu if active element doesn't forbid close via esc.
			 */
			const activeElement = document.activeElement as HTMLElement
			if (!activeElement.dataset.overrideEsc) {
				this.closeMenu()
			}
		},
		tabindexHandler() {
			/**
			 *  Temporarily removes inactive menu levels from tabindex.
			 */
			setTimeout(() => {
				const listsWrapper = this.$refs['lists-wrapper'] as HTMLElement
				const allTabbable = Array.from(
					listsWrapper.querySelectorAll('a,button')
				) as HTMLElement[]

				let activeMenusTabbables: HTMLElement[] = []

				/**
				 * Collects all active menus (the ones that are visible on screen).
				 */
				const activeMenus = listsWrapper.querySelectorAll(
					`[data-active="true"]`
				) as NodeListOf<Element>

				/**
				 * Collects all tabbable elements within all active menus.
				 */
				activeMenus.forEach(menu => {
					activeMenusTabbables = [
						...activeMenusTabbables,
						...(Array.from(menu!.querySelectorAll('a,button')) as HTMLElement[])
					]
				})

				/**
				 * Sets tabindex -1 on all tabbable menu elements
				 * that are not inside an active menu.
				 */
				allTabbable.forEach(element => {
					if (!activeMenusTabbables.includes(element))
						element.setAttribute('tabindex', '-1')
					else element.removeAttribute('tabindex')
				})
			}, 1)
		},
		initialTabHandler() {
			if (this.activeSubmenuId) this.tabindexHandler()
		},
		focusOnFirstTabbable() {
			/**
			 * Sets focus on first tabbable element of the entire app.
			 */
			const wrapper = this.$refs['top-banner'] as HTMLElement
			const firstTabbable = wrapper.querySelector('a, button') as HTMLElement
			firstTabbable.focus()
		},
		focusOnLastTabbable() {
			/**
			 * Sets focus on last tabbable element of the entire app.
			 */
			const appWrapper = this.$refs['app-wrapper'] as HTMLElement
			const allTabbable = Array.from(
				appWrapper.querySelectorAll(
					'a:not([tabindex="-1"]),button:not([tabindex="-1"])'
				)
			) as HTMLElement[]

			/**
			 * Sets focus 	on the last item of the array
			 */
			allTabbable[allTabbable.length - 1].focus()
		},
		bodyFreezeHandler(isActive) {
			/**
			 * Toggles document.body scroll.
			 */
			const documentBody = document.querySelector<HTMLBodyElement>('body')!
			const freezeClassName: string = 's-header-mobile__body-freeze'
			if (isActive === true) {
				documentBody.classList.add(freezeClassName)
				window.scrollTo(0, 0)
			} else documentBody.classList.remove(freezeClassName)
		},
		clickOutsideSuggestions(event) {
			// const paths = event.path
		},
		desktopModeHandler() {
			/**
			 * Detects if in desktop mode or not.
			 *
			 * If leaving desktop:
			 * 	- Updates local state data.
			 * 	- Freezes the rest of the website if menu is expanded.
			 *
			 * If entering desktop:
			 * 	- Updates local state data.
			 * 	- Unfreezes the rest of the website.
			 */
			this.desktop = window.innerWidth >= this.desktopThreshold ? true : false

			window.addEventListener('resize', () => {
				if (window.innerWidth >= this.desktopThreshold && !this.desktop) {
					this.desktop = true
					this.bodyFreezeHandler(false)
				}
				if (window.innerWidth < this.desktopThreshold && this.desktop) {
					this.desktop = false
					if (this.menuExpanded) this.bodyFreezeHandler(true)
				}
			})
		},
		mightTabOutOfApp() {
			/**
			 * A focus trap to keep the user inside the app if it's expanded.
			 *
			 * If:
			 * - The element that triggered this is the
			 * 	 first element of the app.
			 * - Menu is expanded.
			 * - Sent an operation request for handling focus outside app.
			 */
			const appWrapper = this.$refs['app-wrapper'] as Element
			setTimeout(() => {
				const activeElement = document.activeElement as HTMLElement
				if (!appWrapper.contains(activeElement) && this.menuExpanded)
					this.focusOnLastTabbable()
			}, 60)
		}
	},
	watch: {
		menuExpanded(value) {
			this.bodyFreezeHandler(value)
		},
		activeSubmenuId() {
			/**
			 * Will triggers everytime the menu view changes
			 * whether a new submenu id is set or if its unset.
			 */
			this.tabindexHandler()
		}
	},
	computed: {
		isLangSwedish() {
			return this.$store.state.isLangSwedish
		},
		mainMenuItems() {
			return this.$store.getters.mainMenuItems
		},
		secondaryMenuItems() {
			return this.$store.getters.secondaryMenuItems
		},
		activeSubmenuId() {
			return this.$store.getters.activeSubmenu
		},
		noActiveSubmenu() {
			return this.$store.getters.activeSubmenu === undefined
		},
		labels() {
			return this.$store.state.labels
		}
	},
	components: {
		MenuList,
		Logotype,
		MenuToggle,
		SubmenuBackButton,
		TipButton,
		SearchWrapper,
		SubmenuHeading
	}
})
