export interface IMenuItem {
	name: string
	uri: string
	active?: boolean
	id: string
	children?: IMenuItem[]
	secondaryMenuItem?: boolean
	lang?: string
}

export interface IImage {
	id: string
	uri: string
	alt: string
	link?: string
}
export interface ILink {
	id: string
	label: string
	uri: string
}
export interface IApiUrls {
	search: string
}
export interface ISuggestion {
	name: string
	uri: string
}
export interface ILabels {
	menu: string
	close: string
	placeholder: string
	ariaLabelButton: string
	ariaLabelMainMenu: string
	ariaLabelSecondaryMenu: string
	ariaLabelGoBack: string
	suggestions: string
}
export interface IActiveSuggestion {
	index: number
	text: string
}

export interface IState {
	suggestions?: ISuggestion[]
	showSuggestions: boolean
	activeSuggestion?: IActiveSuggestion
	searchQuery?: string
	lastTyped?: string
	menuItems: IMenuItem[]
	secondaryMenuItems: IMenuItem[]
	activeSubmenu?: string
	startPageUri: string
	isStartPage: boolean
	currentPage: string
	isLangSwedish: boolean
	images: IImage[]
	links: ILink[]
	labels: ILabels
	apiUrls: IApiUrls
}

const deafultState: IState = {
	suggestions: [],
	showSuggestions: false,
	activeSuggestion: undefined,
	searchQuery: undefined,
	lastTyped: undefined,
	menuItems: [],
	secondaryMenuItems: [],
	activeSubmenu: undefined,
	startPageUri: '#',
	isStartPage: true,
	isLangSwedish: true,
	currentPage: '4.57d4d7921772a0a401f220',
	images: [
		{
			id: 'logotype',
			uri: '#',
			alt: 'placeholder alt text',
			link: '#'
		}
	],
	links: [
		{
			id: 'tipLink',
			label: 'Tipsa oss',
			uri: '#'
		}
	],
	labels: {
		menu: '',
		close: '',
		placeholder: '',
		ariaLabelButton: '',
		ariaLabelMainMenu: '',
		ariaLabelSecondaryMenu: '',
		ariaLabelGoBack: '',
		suggestions: ''
	},
	apiUrls: {
		search: ''
	}
}

export default deafultState
