












































import Vue from 'vue'
import {
	setSuggestions,
	setShowSuggestions,
	setSearchQuery
} from '../store/actions'
import { ILink, ISuggestion, ILabels } from '../store/state'
import SearchSuggestions from './SearchSuggestions.vue'
import { EventBus } from '../utils/event-bus'

//EventBus.$emit('tab-to-first-suggestion')

interface SWData {
	inputId: string
}

interface SWMethods {
	clearSearch(): void
	navigateSuggestions(direction: string): void
	focusOnSearchButton(): void
	goToExternalSearchResult(input: string): void
	searchSuggestionsHandler(query: string): void
	setSearchQuery(value?: string): void
	setShowSuggestions(show: boolean): void
	focusOnSearchInput(): void
	onInputChange(event: any): void
	moveCursorToEndOfInput(el: any): void
}

interface SWComputed {
	link: ILink
	getSuggestions?: ISuggestion[]
	getSearchQuery: string
	getShowSuggestions: boolean
	labels: ILabels
	activeDescendant?: string
}

export default Vue.extend<SWData, SWMethods, SWComputed, {}>({
	components: { SearchSuggestions },
	name: 'SearchWrapper',
	data() {
		return {
			inputId: 's-header-mobile__search__input'
		}
	},
	mounted() {
		EventBus.$on('focus-on-search-input', () => this.focusOnSearchInput())
		EventBus.$on('focus-on-search-input', () => this.focusOnSearchInput())
		EventBus.$on('focus-on-search-button', () => this.focusOnSearchButton())
		EventBus.$on('clear-search', () => this.clearSearch())
		EventBus.$on('hide-suggestions', () => this.setShowSuggestions(false))
		EventBus.$on('submit-form', () => (this.$refs['search-form'] as HTMLFormElement).submit())
	},
	watch: {
		getSearchQuery: function() {
			this.moveCursorToEndOfInput(
				this.$refs['search-input'] as HTMLInputElement
			)
		}
	},
	methods: {
		clearSearch() {
			/**
			 * Clears search input. Sets focus on it.
			 */
			this.setSearchQuery(undefined)
			this.setShowSuggestions(false)
			const searchInputElement = this.$refs['search-input'] as HTMLElement
			searchInputElement.focus()
		},
		navigateSuggestions(direction) {
			/**
			 * Sets focus on first suggestion
			 */
			EventBus.$emit('navigate-suggestion', direction)
		},
		focusOnSearchButton() {
			/**
			 * Sets focus on search button. Places the marker at end of textcontent.
			 */
			const searchInputElement = this.$refs['search-input'] as HTMLInputElement
			const searchButtonElement = this.$refs[
				'search-button'
			] as HTMLButtonElement

			this.setSearchQuery(searchInputElement.value)
			searchButtonElement.focus()
		},
		goToExternalSearchResult() {
			/**
			 * Sends user to external search results page
			 */
			// const target: string = `${this.link.uri}/?query=${this.getSearchQuery}`
			// window.location.href = target
		},
		searchSuggestionsHandler(query) {
			/**
			 * Fetches search suggestions from Sitevision server
			 * if search term length >= 3
			 */

			if (!(query && query.trim().length >= 3))
				this.$store.dispatch(setSuggestions(undefined))
			else {
				this.setShowSuggestions(true)
				this.$store.dispatch(setSuggestions(query))
			}
		},
		setSearchQuery(value) {
			/**
			 * Set value of input field in state
			 */
			this.$store.dispatch(setSearchQuery(value))
		},
		setShowSuggestions(value) {
			/**
			 * Tell state if suggestions part is gonna show o not
			 */
			this.$store.dispatch(setShowSuggestions(value))
		},
		focusOnSearchInput() {
			/**
			 * Sets focus on search input field
			 */
			const searchInputElement = this.$refs['search-input'] as HTMLInputElement
			if (searchInputElement) searchInputElement.focus()
		},
		onInputChange(event) {
			const target = event.target as HTMLInputElement
			const value = target.value || ''

			this.$store.commit({ type: 'setLastTyped', payload: value })
			this.setSearchQuery(value)

			if (value.length < 3) this.setShowSuggestions(false)
			else this.searchSuggestionsHandler(value)
		},
		moveCursorToEndOfInput(el) {
			setTimeout(() => {
				if (typeof el.selectionStart === 'number') {
					el.selectionStart = el.selectionEnd = el.value.length
				} else if (typeof el.createTextRange !== 'undefined') {
					el.focus()
					var range = el.createTextRange()
					range.collapse(false)
					range.select()
				}
			})
		}
	},
	computed: {
		link() {
			return this.$store.state.links.find(
				(link: ILink) => link.id === 'searchResultsPage'
			)
		},
		getSuggestions() {
			return this.$store.getters.suggestions
		},
		getSearchQuery() {
			return this.$store.getters.searchQuery
		},
		getShowSuggestions() {
			return this.$store.getters.showSuggestions
		},
		labels() {
			return this.$store.state.labels
		},
		activeDescendant() {
			const output = this.$store.getters.activeSuggestion
			return output && `s-header-mobile__search__suggestion-${output.index}`
		}
	}
})
