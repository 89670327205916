
























import Vue from 'vue'
import { IImage } from '../store/state'
import { EventBus } from '../utils/event-bus'

interface LTProps {
	hidden: boolean
}
interface LTMethods {
	mightTabOutOfApp():void
}
interface LTComputed {
	isStartPage: boolean
	startPageUri: string
	logotype: IImage
}

export default Vue.extend<{}, LTMethods, LTComputed, LTProps>({
	name: 'Logotype',
	props: {
		hidden: Boolean
	},
	methods: {
		mightTabOutOfApp(){
			EventBus.$emit('might-tab-out-of-app')
		}
	},
	computed: {
		isStartPage() {
			return this.$store.state.isStartPage
		},
		startPageUri() {
			return this.$store.state.startPageUri
		},
		logotype() {
			return this.$store.state.images.find((x: IImage) => x.id === 'logotype')
		}
	}
})
