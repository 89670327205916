










import Vue from 'vue'
import { ILink } from '../store/state'

interface TBProps {
	hidden: boolean
}
interface TBComputed {
	link: ILink
}

export default Vue.extend<{}, {}, TBComputed, TBProps>({
	name: 'TipButton',
	props: {
		hidden: Boolean
	},
	computed: {
		link() {
			return this.$store.state.links.find(
				(link: ILink) => link.id === 'tipLink'
			)
		}
	}
})
